var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"visible",attrs:{"id":"secondary-nav"}},[(_vm.selectedRootNav && _vm.selectedRootNav.subsections.length > 0)?_c('div',{staticClass:"s-nav-links"},_vm._l((_vm.selectedRootNav.subsections),function(subsection,rootIndex){return _c('div',{key:'s-nav' + rootIndex,class:("s-nav-link " + ('s-nav' + rootIndex) + " " + (_vm.activeSubsectionId === 's-nav' + rootIndex ? 'active' : '')),on:{"click":function($event){return _vm.subsectionClicked('s-nav' + rootIndex, subsection)}}},[(
          subsection.subsections &&
          subsection.subsections[0] &&
          !subsection.ignoreRouteSkip
        )?_c('router-link',{staticClass:"sideBtn-main",attrs:{"to":_vm.selectedRootNav.to +
          subsection.subroute +
          subsection.subsections[0].subroute}},[_vm._v(_vm._s(subsection.title))]):_c('router-link',{staticClass:"sideBtn-main",attrs:{"to":_vm.selectedRootNav.to + subsection.subroute}},[_vm._v(_vm._s(subsection.title))]),(
          _vm.selectedSubNav &&
          subsection.id === _vm.selectedSubNav.id &&
          _vm.selectedSubNav.subsections
        )?_c('div',{staticClass:"expanded-subsections"},_vm._l((_vm.selectedSubNav.subsections),function(nestedSubsection,index){return _c('div',{key:'n-nav' + index,class:("e-nav-link " + (_vm.activeSubsectionId === 'n-nav' + index ? 'active' : '')),on:{"click":function($event){return _vm.subsectionClicked('s-nav' + rootIndex, subsection)}}},[_c('router-link',{staticClass:"sideBtn-sub",attrs:{"to":_vm.selectedRootNav.to +
              _vm.selectedSubNav.subroute +
              nestedSubsection.subroute}},[_vm._v(_vm._s(nestedSubsection.title))])],1)}),0):_vm._e()],1)}),0):_vm._e(),(_vm.repEmail)?_c('div',{staticClass:"repInfo"},[_c('a',{attrs:{"href":("mailto:" + _vm.repEmail)}},[_vm._v("Contact "+_vm._s(_vm.repName))])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }