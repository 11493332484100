<template>
  <div class="visible" id="secondary-nav">
    <div
      class="s-nav-links"
      v-if="selectedRootNav && selectedRootNav.subsections.length > 0"
    >
      <div
        v-for="(subsection, rootIndex) in selectedRootNav.subsections"
        :key="'s-nav' + rootIndex"
        :class="`s-nav-link ${'s-nav' + rootIndex} ${
          activeSubsectionId === 's-nav' + rootIndex ? 'active' : ''
        }`"
        @click="subsectionClicked('s-nav' + rootIndex, subsection)"
      >
        <router-link
          v-if="
            subsection.subsections &&
            subsection.subsections[0] &&
            !subsection.ignoreRouteSkip
          "
          :to="
            selectedRootNav.to +
            subsection.subroute +
            subsection.subsections[0].subroute
          "
          class="sideBtn-main"
          >{{ subsection.title }}</router-link
        >
        <router-link
          v-else
          :to="selectedRootNav.to + subsection.subroute"
          class="sideBtn-main"
          >{{ subsection.title }}</router-link
        >

        <div
          class="expanded-subsections"
          v-if="
            selectedSubNav &&
            subsection.id === selectedSubNav.id &&
            selectedSubNav.subsections
          "
        >
          <div
            v-for="(nestedSubsection, index) in selectedSubNav.subsections"
            :key="'n-nav' + index"
            :class="`e-nav-link ${
              activeSubsectionId === 'n-nav' + index ? 'active' : ''
            }`"
            @click="subsectionClicked('s-nav' + rootIndex, subsection)"
          >
            <router-link
              :to="
                selectedRootNav.to +
                selectedSubNav.subroute +
                nestedSubsection.subroute
              "
              class="sideBtn-sub"
              >{{ nestedSubsection.title }}</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <div class="repInfo" v-if="repEmail">
      <a :href="`mailto:${repEmail}`">Contact {{ repName }}</a>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "SideBarNav",
  computed: {
    ...mapState(["epitch", "global"]),
  },
  watch: {
    $route: function (newRoute, oldRoute) {
      if (!newRoute) return;
      this.handleRoute(newRoute.path);
    },
    selectedRootNav: function (newRootNav, oldRootNav) {
      if (!newRootNav) return;
      this.activeSubsectionId = "";
      if (newRootNav.subsections.length > 0) {
        document.getElementById("secondary-nav").classList.add("visible");
      } else {
        document.getElementById("secondary-nav").classList.remove("visible");
      }
    },
  },
  data() {
    return {
      selectedRootNav: null,
      selectedSubNav: null,
      activeSubsectionId: "",
      repName: null,
      repEmail: null,
    };
  },
  mounted() {
    this.handleRoute(this.$route.path);
    //window.console.log("mounted");
    let repInfo = this.$store.state.epitch.ePitch.rep;
    // this.repName = repInfo.first;
    // this.repEmail = repInfo.email;
  },
  updated() {
    // this.handleRoute(this.$route.path);
    //window.console.log("mounted");
    let repInfo = this.$store.state.epitch.ePitch.rep;
    this.repName = repInfo.first;
    this.repEmail = repInfo.email;
  },
  methods: {
    handleRoute(pathname) {
      if (
        !this.epitch ||
        !this.epitch.sections ||
        !this.epitch.sections.length ||
        this.epitch.sections.length < 1
      ) {
        return;
      }
      const epitchSections = this.epitch.sections;

      let paths = pathname.split("/");
      if (!paths) return;
      paths = paths
        .filter((path) => path && path != "")
        .map((path) => "/" + path);

      let selectedRootNav = null;
      if (paths.length >= 1) {
        selectedRootNav = epitchSections.find(
          (epitchSection) => epitchSection.to === paths[0]
        );
        this.selectedRootNav = selectedRootNav;
      }
      if (paths.length > 1) {
        this.selectedSubNav = selectedRootNav.subsections.find(
          (epitchSection) => epitchSection.subroute === paths[1]
        );
      }
    },
    subsectionClicked(subsectionId, subsection) {
      this.activeSubsectionId = subsectionId;
    },
  },
};
</script>

<style lang="scss" scoped>
#secondary-nav {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: -100%;
  opacity: 0;
  width: 268px;
  height: 100vh;
  background-image: linear-gradient(
    269deg,
    rgba(255, 255, 255, 0.08) 0%,
    rgba(255, 255, 255, 0) 69%
  );
  transition: all 0.5s ease-in-out;
  z-index: 100;
  &.visible {
    left: 0;
    opacity: 1;
  }
  @media (max-width: $xl) {
    //padding-right: 6.8vw;
  }
  /**Adjust this padding for mobile, remove and justify center */
  .s-nav-links {
    display: flex;
    height: 100vh;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    .s-nav-link {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 20px;
      .sideBtn-main {
        padding: 8px 0;
        font-size: 12px;
        font-family: "ArtegraSans-Bold";
        line-height: 1;
        letter-spacing: 0.81px;
        text-decoration: none;
        text-align: left;
        text-transform: uppercase;
        color: #9d9d9d;
        @media (max-height: 700px) {
          padding: 5px 0;
        }
        &.is-active,
        &.router-link-active {
          color: #ffffff;
        }
      }
      &.active > a {
        color: #ffffff;
      }
    }
    .expanded-subsections {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10px 0;
      @media (max-height: 700px) {
        padding: 5px 0;
      }
      .e-nav-link {
        padding-left: 15px;
        margin: 4px 0;
        @media (max-height: 700px) {
          margin: 3px 0;
        }
        .sideBtn-sub {
          font-size: 12px;
          font-family: "ArtegraSans-Bold";
          line-height: 16px;
          letter-spacing: 0.81px;
          text-decoration: none;
          text-align: left;
          text-transform: uppercase;
          color: #9d9d9d;
          display: block;

          &.is-active {
            color: #ffffff;
          }
        }
      }
    }
  }
  .repInfo {
    display: block;
    width: 100%;
    margin-bottom: 30px;

    a {
      font-size: 11px;
      font-family: "ArtegraSans-Bold";
      line-height: 14px;
      letter-spacing: 0.81px;
      text-decoration: none;
      text-align: left;
      text-transform: uppercase;
      color: #ffffff;
      width: 100%;
      padding: 0 30px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        width: calc(100% - 60px);
        height: 2px;
        background: #ffffff;
        box-shadow: 0 0 9px 2px #ffffff;
        left: 30px;
        top: -15px;
      }
    }
  }
}
</style>
